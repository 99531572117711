import './App.css';
import React, {useState, useEffect, useRef} from "react";

const API = "https://worker-shiny-tooth-bf76.runtime-playground.workers.dev/";

function PetItem(props) {
  const vote = async() => {
    const req = await fetch(API + "vote", {
      method: "POST",
      body: JSON.stringify({name: props.name}),
      headers: 
      {
        "Content-Type": "application/json"
      }
    });

    const {score} = await req.json();
    props.updateScore(score);
  }

  return (
    <tr>
      <td>
        <div style={{
          width: 200,
          overflow: "auto"
        }}>
          {props.name}
        </div>
      </td>
      <td>{props.score}</td>
      <td><button className="button" onClick={vote}>Vote</button></td>
    </tr>
  )
}

function AddForm(props) {
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const name = inputRef.current.value;
    console.log(name);
    const req = await fetch(API + "create", {
      method: "POST",
      body: JSON.stringify({name: name}),
      headers: 
      {
        "Content-Type": "application/json"
      }
    });

    setLoading(false);

    setStatus(await req.text());

    props.refresh();
  }

  if (loading) {
    return <div style={{
      marginTop: 20,
      fontSize: 15
    }}>Verifying your submission...</div>
  }

  return (
    <div>
      <form onSubmit={submit} style={{marginTop: 20}}>
        <input className="input-box" placeholder="Add a new pet" ref={inputRef}></input>
        <input className="button" type="submit"></input>
      </form>
      <div style={{marginTop: 10, fontSize: 15}}>{status}</div>
    </div>
  )
}

function PetList() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await (await fetch(API)).json();

    setData(res);
  };

  const updateScore = (name, newScore) => {
    setData(data.map((obj) => {
      if(obj.name === name){
        return {...obj, score: newScore};
      }
      return obj;
    }));
  }

  useEffect(() => {
    fetchData();
    setInterval(fetchData, 1000);
  }, []);

  return (
    <div>
      <table>
        <tr>
          <th>Name</th>
          <th>Score</th>
          <th></th>
        </tr>
        {data.map(d => <PetItem 
          key={d.name} name={d.name} score={d.score} 
          updateScore={updateScore.bind(this, d.name)}
          />)}
      </table>
      <AddForm refresh={fetchData} />
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Vote for Your Favorite Pets!</h1>
        <PetList />

      </header>
    </div>
  );
}

export default App;
